//
// components-rtl.scss
//



// icons

.icon-list-demo{
  i{
    margin-left: 12px;
    margin-right: 0;
  }
}


// Invoice

@media print {
  .content-page,
  .content,
  body {
      margin-right: 0;
  }
}

// Demos button 
.demos-show-btn {
  left: 0;
  right: auto;
  border-radius: 0 6px 6px 0;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: $border-radius;
  border-top-left-radius: $border-radius;
}

.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
}


// search-box

.search-box{
  .form-control{
    padding-right: 40px;
    padding-left: $input-padding-x;
  }
  .search-icon{
    left: auto;
    right: 13px;
  }
}