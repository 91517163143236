// 
// bootstrap-rtl.scss
//

//alert
.alert-dismissible{
  padding-left: $alert-dismissible-padding-r;
  padding-right: var(--#{$prefix}alert-padding-x);

  .btn-close{
    right: auto;
    left: 0;
  }
}


// accordion
.accordion-button::after{
  margin-right: auto;
  margin-left: 0;
}




.breadcrumb-item {
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    padding-left: 0;
    padding-right: var(--#{$prefix}breadcrumb-item-padding-x);

    &::before {
      float: right; // Suppress inline spacings and underlining of the separator
      padding-right: 0;
      padding-left: var(--#{$prefix}breadcrumb-item-padding-x);
    }
  }

  &.active {
    color: var(--#{$prefix}breadcrumb-item-active-color);
  }
}

// dropdown

.dropdown-item{
  text-align: right;
}
.dropdown-toggle::after {
  margin-right: .255em;
  margin-left: auto;
}

.dropdown-menu{
  left: auto !important;
  right: 0 !important;
  text-align: right;
}

.dropdown-menu-end[style]{
  right: auto !important;
  left: 0 !important;
}

.dropdown-megamenu{
  &.show{
    left: 20px!important;
  }
}

// List

ul {
  padding-right: 0;
}

.list-inline-item:not(:last-child) {
  margin-left: $list-inline-padding;
  margin-right: 0px;
}

// border

.border-right {
  border-left: $border-width solid $border-color !important;
}

.border-left {
  border-right: $border-width solid $border-color !important;
}


//Buttons 

//button with icon
.btn-label {
  position: relative;
  padding-right: 44px;
  padding-left: $btn-padding-x;

  .label-icon {
      left: auto;
      right: -$btn-border-width;
      border-right: 0;
      border-left: 1px solid rgba($white, 0.1);
  }

  &.btn-light {
    .label-icon {
        background-color: rgba($dark, 0.1);
        border-right: 0;
        border-left: 1px solid rgba($dark, 0.2);
    }
}
}

.btn-group>:not(.btn-check)+.btn {
  border-radius: 0px;
  border-top-left-radius: $btn-border-radius;
  border-bottom-left-radius: $btn-border-radius;
}


.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0px;
  border-top-right-radius: $btn-border-radius;
  border-bottom-right-radius: $btn-border-radius;
}
.btn-group >{
  .btn:nth-child(n+3), 
  :not(.btn-check) + .btn, 
  .btn-group:not(:first-child) > .btn {
      border-radius: 0px;
      border-top-left-radius: $btn-border-radius;
      border-bottom-left-radius: $btn-border-radius;
  }

  :not(.btn-check:first-child) + .btn, 
  .btn-group:not(:first-child) {
      margin-left: 0;
      margin-right: -1px;
  }
}
.btn-group-example{
  .btn:before{
    left: auto;
    right: -12px;
  }
}


// list
ol,
ul {
    padding-left: 0;
  padding-right: 2rem;
}
.list-unstyled{
  padding-right: 0;
}

.list-group {
  padding-right: 0px;
}

.list-inline-item {

  &:not(:last-child) {
    margin-right: 0;
    margin-left: $list-inline-padding;
  }
}

.list-group-horizontal-md>.list-group-item:first-child {
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-horizontal-md>.list-group-item:last-child {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.list-group-horizontal-md>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-right-width: 0;
  border-left-width: 1px;
}

.list-group-horizontal-md>.list-group-item+.list-group-item.active {
  margin-right: -1px;
  border-right-width: 1px;
}

.page-item {
  &:not(:first-child) {
      .page-link {
          margin-right: -1px;
      }
  }
}

dd {
  margin-right: 0;
}

// Nav

.nav{
  padding-right: 0;
}

// modal
.modal-open {
  padding-right: 0px !important;
}
.modal{
  .btn-close{
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}


// select
.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-left: 1.75rem;
}
.form-select{
  padding: $form-select-padding-y $form-select-padding-x $form-select-padding-y $form-select-indicator-padding;
  background-position: left 0.75rem center;
}


// form-check
.form-check{
  text-align: right;
  padding-right: $form-check-padding-start;
  padding-left: 0;
  .form-check-input {
      float: right;
      margin-left: 0;
      margin-right:  $form-check-padding-start * -1;
  }

  &.card-radio{
      padding: 0;
  }
}

.form-floating {
  >label {
    right: 0;
    left: auto;
    transform-origin: 100% 0;
  }
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      transform: scale(.85) translateY(-.5rem) translateX(-.15rem);
    }
  }
}

.form-check-right{
  text-align: left;
  padding-left: $form-check-padding-start;
  padding-right: 0;

  .form-check-input{
    float: left;
    margin-right: 0;
    margin-left:  $form-check-padding-start * -1;
  }
}

.form-check-inline {
  margin-right: 0;
  margin-left: $form-check-inline-margin-end;
}

.form-checkbox-outline .form-check-input:checked:after{
  right: -3px;
  left: auto;
}

// form-switch
.form-switch {
  padding-left: 0;
  padding-right: $form-switch-padding-start;
  .form-check-input{
      margin-left: 0;
      margin-right: $form-switch-padding-start * -1;
  }
}

.form-switch-md {
  padding-left: 0;
  padding-right: 2.5rem;
  .form-check-input {
      left: auto;
      right: -0.5rem;
  }
}

.form-switch-lg{
  padding-left: 0;
  padding-right: 2.75rem;
  .form-check-input {
      left: auto;
      right: -0.75rem;
  }
}

// position
.start-0, .start-50, .start-100{
  &.translate-middle{
      transform: translate(50%, -50%) !important;
  }
}

.start-0{
  right: 0%;
  left: auto !important;
}

.start-50{
  right: 50%;
  left: auto !important;
  &.translate-middle-x{
      transform: translateX(50%) !important;
  }
}

.start-100{
  right: 100%;
  left: auto !important;
}

.end-50 {
  right: auto !important;
  left: 50%;
}

// pagination

.pagination{
  padding: 0;
}

.page-item{
  &:first-child .page-link {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: var(--#{$prefix}pagination-border-radius);
      border-bottom-right-radius: var(--#{$prefix}pagination-border-radius);
  }

  &:last-child .page-link {
      border-top-left-radius: var(--#{$prefix}pagination-border-radius);
      border-bottom-left-radius: var(--#{$prefix}pagination-border-radius);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
}


// toast
.toast-header {
  .btn-close {
    margin-left: calc(-.5 * var(--#{$prefix}toast-padding-x));
    margin-right: var(--#{$prefix}toast-padding-x);
  }
}
// ------------ FORM  //

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: right;
  }
}


.bx-fade-left
{
            animation: fade-right 1.5s infinite linear !important;
}
.bx-fade-left-hover:hover
{
            animation: fade-right 1.5s infinite linear !important;
}
.bx-fade-right
{
            animation: fade-left 1.5s infinite linear !important;
}
.bx-fade-right-hover:hover
{
            animation: fade-left 1.5s infinite linear !important;
}
